<template>
  <HandleDataForms v-bind:apiSource="mongo" v-bind:dataSource="'/'+database" title="Cotizaciones" collection="cotizaciones" templateTable="TableCotizacionesForm" templateTableModal="TableCotizacionesModal" v-bind:dataExcel="dataExcel" />
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import HandleDataForms from '@/components/HandleDataForms.vue'

export default {
  components: {
    HandleDataForms,
  },
  setup() {
    const store = useStore()
    const mongo = computed(() => store.getters.getMongoURL)
    const database = computed(() => store.getters.getDBDashboard)
    const startDate = computed(() => moment().startOf('week').format('YYYY-MM-DD'))
    const endDate = computed(() => moment().format('YYYY-MM-DD'))

    let dataExcel = {
      fields: {
        fecha: 'Fecha',
        contactId: 'ID de Contacto',
        crm_user_id: 'ID de usuario CRM',
        nombre: 'Nombre',
        apellido_paterno: 'Apellido paterno',
        apellido_materno: 'Apellido materno',
        nacionalidad: { nombre: 'Nacionalidad' },
        tipo_documento: 'Tipo de  documento',
        rut: 'Nº de documento',
        pasaporte: 'Pasaporte',
        email: 'E-mail',
        celular_codigo: 'Código celular',
        celular_numero: 'Número celular',
        telefono_codigo: 'Código teléfono',
        telefono_numero: 'Número teléfono',

        region: { nombre: 'Región' }, // Region es un valor compuesto
        comuna: { nombre: 'Comuna' }, // Comuna es un valor compuesto
        colegio: { nombre: 'Colegio' }, // Colegio es un valor compuesto

        ano_cursado: 'Año cursado',
        ano_egreso: 'Año de egreso',

        postulacion: [
          {
            carrera: {
              nombre: 'Carrera 1 - Nombre',
            },
            institucion: 'Carrera 1 - Institución',
            jornada: 'Carrera 1 - Jornada',
            zona_admision: {
              nombre: 'Carrera 1 - Zona de admisión',
            },
          },
          {
            carrera: {
              nombre: 'Carrera 2 - Nombre',
            },
            institucion: 'Carrera 2 - Institución',
            jornada: 'Carrera 2 - Jornada',
            zona_admision: {
              nombre: 'Carrera 2 - Zona de admisión',
            },
          },
          {
            carrera: {
              nombre: 'Carrera 3 - Nombre',
            },
            institucion: 'Carrera 3 - Institución',
            jornada: 'Carrera 3 - Jornada',
            zona_admision: {
              nombre: 'Carrera 3 - Zona de admisión',
            },
          },
        ],
        carrera_1: 'Codigo Carrera 1',
        carrera_2: 'Codigo Carrera 2',
        carrera_3: 'Codigo Carrera 3',
        utm_source: 'Origen UTM',
        utm_campaign: 'Campaña UTM',
        utm_medium: 'Medio UTM',
        device: 'Dispositivo',
        historial: 'Historial',
      },
      query: {
        collection: 'cotizaciones',
        fecha_inicio: startDate.value + ' 00:00:00',
        fecha_fin: endDate.value + ' 23:59:59',
      },
    }

    return {
      mongo,
      dataExcel,
      database
    }
  },
}
</script>
